var Comments;

Comments = (function() {
  return {
    symbols: 0,
    torrent: 0,
    max_symbols: 255,
    modal: $('#bls-comments'),
    chat_window: $('#chat-window'),
    label: $('.modal-title-lable'),
    form: $('form.comment-form'),
    sliders: $('.rate-slider'),
    textarea: $('.bls-comment'),
    counter: $('.comment-count'),
    loader: $(".chat-info.loader"),
    loader_error: $(".chat-info.error"),
    loader_empty: $(".chat-info.empty"),
    init: function() {
      var self;
      self = this;
      this.symbols = this.textarea.val().length;
      this.counter.html(this.max_symbols - this.symbols);
      this.sliders.slider();
      this.templates = {
        left: $('.chat-template.left', this.modal),
        right: $('.chat-template.right', this.modal)
      };
      this.textarea.unbind('change keyup keydown paste');
      this.textarea.on('change keyup keydown paste', function(e) {
        var diff, text;
        diff = self.max_symbols - self.symbols;
        text = $(this).val();
        self.symbols = text.length;
        if (diff <= 0) {
          diff = 0;
          $(this).val(text.slice(0, self.max_symbols));
        }
        return self.counter.html(diff);
      });
      this.form.on("submit", function(e) {
        e.preventDefault();
        return self.sendForm($(this).serializeArray());
      });
      $('.submit-comment', this.modal).on("mousedown", function(e) {
        e.preventDefault();
        return self.form.submit();
      });
      return this;
    },
    replace_template: function(item, dir) {
      var initials, template;
      template = this.templates[dir].clone();
      if (item.anonymous) {
        $('.avatar-circle', template).text('N/A');
      } else {
        initials = "";
        $.each(item.name.split(" "), function(i, part) {
          return initials += part.slice(0, 1).toUpperCase();
        });
        $('.avatar-circle', template).text(initials);
      }
      $('strong.primary-font', template).html(item.name);
      $('.comment-time', template).html(TorrentsTable.format_date(item.created));
      $('em.rate-audio', template).html(item.audio || 0);
      $('em.rate-video', template).html(item.video || 0);
      $('.chat-body p', template).html(item.content);
      template.addClass("chat-message");
      template.removeClass("chat-template");
      return template;
    },
    insert_comment: function(data) {
      var dir, index, item, j, len, results;
      results = [];
      for (index = j = 0, len = data.length; j < len; index = ++j) {
        item = data[index];
        if (item == null) {
          continue;
        }
        dir = ((index % 2) === 0 ? "left" : "right");
        if ((item != null ? item.name : void 0) == null) {
          item.anonymous = true;
        }
        if (item != null ? item.anonymous : void 0) {
          item.name = 'Anonymous';
        }
        if (item.content != null) {
          results.push(this.chat_window.prepend(this.replace_template(item, dir)));
        } else {
          results.push(void 0);
        }
      }
      return results;
    },
    data_error: function(error) {
      this.loader.addClass('hidden');
      this.loader_error.removeClass('hidden');
      this.loader_empty.addClass('hidden');
      return console.error(error.status + ": " + error.statusText);
    },
    loadComments: function() {
      var self;
      self = this;
      this.loader.removeClass('hidden');
      this.loader_error.addClass('hidden');
      this.loader_empty.addClass('hidden');
      return $.ajax({
        url: this.modal.data('comments'),
        method: 'post',
        data: {
          id: this.torrent
        },
        success: function(data) {
          if ((data == null) || (data != null ? data.error : void 0)) {
            self.data_error({
              status: 500,
              statusText: 'Server error'
            });
            return;
          }
          if ((data != null) && ((data != null ? data.comments : void 0) != null) && !(data != null ? data.error : void 0)) {
            if ((data != null ? data.comments.length : void 0) > 0) {
              self.insert_comment(data.comments);
              return self.loader.addClass('hidden');
            } else {
              self.loader.addClass('hidden');
              return self.loader_empty.removeClass('hidden');
            }
          }
        },
        error: function(error) {
          return self.data_error(error);
        }
      });
    },
    sendForm: function(content) {
      var self;
      self = this;
      content.push({
        name: 'appbundle_comment[fingerprint]',
        value: FINGERPRINT
      });
      return $.ajax({
        url: this.form.attr('action'),
        method: 'post',
        data: content,
        success: function(data) {
          if ((data == null) || (data != null ? data.error : void 0)) {
            self.data_error({
              status: 500,
              statusText: 'Server error'
            });
            return;
          }
          if ((data != null) && !(data != null ? data.error : void 0)) {
            self.form.trigger('reset');
            $('.chat-message', self.chat_window).remove();
            $('.torrent-parent', self.form).val(self.torrent);
            return self.loadComments();
          }
        },
        error: function(error) {
          return self.data_error(error);
        }
      });
    },
    show: function(id, title) {
      this.torrent = id;
      $('.torrent-parent', this.form).val(id);
      $('.chat-message', this.chat_window).remove();
      this.loader.removeClass('hidden');
      this.label.html(title);
      this.loadComments();
      return this.modal.modal('show');
    }
  };
})();

$(document).ready(function() {
  return Comments.init();
});
